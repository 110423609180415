import fp from 'lodash/fp';

import { Occupancy, OCCUPANCY_VALUES } from './Occupancy';

/**
 * @see http://doc-datafoncier.cerema.fr/ff/doc_fftp/table/pb0010_local/last/
 */
export interface DatafoncierHousing {
  idlocal: string;
  idbat: string;
  idpar: string;
  idtup: string;
  idsec: string;
  idvoie: string;
  idprocpte: string;
  idcom: string;
  idcomtxt: string;
  ccodep: string;
  ccodir: string;
  ccocom: string;
  invar: string;
  ccopre: string | null;
  ccosec: string;
  dnupla: string;
  dnubat: string;
  descc: string;
  dniv: string;
  dpor: string;
  ccoriv: string;
  ccovoi: string;
  dnvoiri: string;
  dindic: string | null;
  ccocif: string;
  dvoilib: string;
  cleinvar: string;
  ccpper: string;
  gpdl: string;
  ctpdl: string | null;
  dnupro: string;
  jdatat: string;
  jdatatv: string;
  jdatatan: number;
  dnufnl: string | null;
  ccoeva: string;
  ccoevatxt: string;
  dteloc: string;
  dteloctxt: string;
  logh: string;
  loghmais: string;
  loghappt: string | null;
  gtauom: string;
  dcomrd: string;
  ccoplc: string | null;
  ccoplctxt: string | null;
  cconlc: string;
  cconlctxt: string;
  dvltrt: number;
  cc48lc: string | null;
  dloy48a: number | null;
  top48a: string;
  dnatlc: string;
  ccthp: string | null;
  proba_rprs: string;
  typeact: string | null;
  loghvac: string | null;
  loghvac2a: string | null;
  loghvac5a: string | null;
  loghvacdeb: string | null;
  cchpr: string | null;
  jannat: string;
  dnbniv: string;
  nbetagemax: number;
  nbnivssol: number | null;
  hlmsem: string | null;
  loghlls: string;
  postel: string | null;
  dnatcg: string | null;
  jdatcgl: string;
  fburx: number;
  gimtom: string | null;
  cbtabt: string | null;
  jdbabt: string | null;
  jrtabt: string | null;
  cconac: string | null;
  cconactxt: string | null;
  toprev: string;
  ccoifp: number;
  jannath: number;
  janbilmin: number;
  npevph: number;
  stoth: number;
  stotdsueic: number;
  npevd: number;
  stotd: number;
  npevp: number;
  sprincp: number;
  ssecp: number;
  ssecncp: number;
  sparkp: number;
  sparkncp: number;
  npevtot: number;
  slocal: number;
  npiece_soc: number;
  npiece_ff: number;
  npiece_i: number;
  npiece_p2: number;
  nbannexe: number;
  nbgarpark: number;
  nbagrement: number;
  nbterrasse: number;
  nbpiscine: number;
  ndroit: number;
  ndroitindi: number;
  ndroitpro: number;
  ndroitges: number;
  catpro2: string;
  catpro2txt: string;
  catpro3: string;
  catpropro2: string;
  catproges2: string;
  locprop: string;
  locproptxt: string;
  source_geo: string;
  vecteur: string;
  ban_id: string;
  ban_geom: string | null;
  ban_type: string;
  ban_score: string;
  ban_cp: string;
  code_epci: string | null;
  lib_epci: string | null;
  geomloc: string | null;
  idpk: number | null;
  dis_ban_ff: number;
}

export function toAddress(housing: DatafoncierHousing): string {
  const streetNumber = fp.trimCharsStart('0', housing.dnvoiri);
  const repetition = housing.dindic ?? '';
  const street = housing.dvoilib;
  const zipcode = housing.idcom;
  const city = housing.idcomtxt;
  return `${streetNumber}${repetition} ${street}, ${zipcode} ${city}`;
}

export function toOccupancy(ccthp: DatafoncierHousing['ccthp']): Occupancy {
  const occupancy = OCCUPANCY_VALUES.find((occupancy) => occupancy === ccthp);
  return occupancy ?? Occupancy.UNKNOWN;
}
